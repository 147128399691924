<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section>
        <div class="row justify-between">
          <p class="text-primary text-h6 text-bold q-ml-sm">
            List Mata Pelajaran
          </p>
        </div>

        <q-tree
          ref="kdtree"
          :expanded.sync="expanded"
          :nodes="customize"
          node-key="uid"
        >
          <template v-slot:default-header="prop">
            <div class="row items-center">
              <div class="text-weight-bold text-primary">
                {{ prop.node.label }}
              </div>
            </div>
            <q-btn
              v-can="['KEPSEK', 'MAPEL', 'KURIKULUM']"
              flat
              dense
              size="sm"
              icon="add"
              label="TAMBAH KD"
              v-if="prop.node.is_btn_add"
              @click.stop=""
              color="primary"
            >
              <q-popup-proxy>
                <q-card>
                  <q-card-section>
                    <q-input
                      stack-label
                      label="Nama KD"
                      v-model="kd_baru"
                    ></q-input>
                  </q-card-section>
                  <q-card-actions align="right">
                    <q-btn
                      flat
                      label="close"
                      color="primary"
                      v-close-popup
                    ></q-btn>
                    <q-btn
                      unelevated
                      dense
                      label="ADD"
                      color="primary"
                      :disable="kd_baru == ''"
                      @click="addhandler(prop.node)"
                      v-close-popup
                    ></q-btn>
                  </q-card-actions>
                </q-card>
              </q-popup-proxy>
            </q-btn>
            <q-btn-group flat class="q-ml-sm" v-if="prop.node.is_btn_del">
              <q-btn
                v-can="['KEPSEK', 'MAPEL', 'KURIKULUM']"
                flat
                size="sm"
                dense
                icon="edit"
                color="secondary"
                @click.stop="toedit = JSON.parse(JSON.stringify(prop.node))"
              >
                <q-popup-proxy>
                  <q-card>
                    <q-card-section>
                      <q-input
                        stack-label
                        label="Nama KD"
                        v-model="toedit.label"
                      ></q-input>
                    </q-card-section>
                    <q-card-actions align="right">
                      <q-btn
                        flat
                        label="close"
                        color="primary"
                        v-close-popup
                      ></q-btn>
                      <q-btn
                        unelevated
                        dense
                        label="EDIT"
                        color="primary"
                        :disable="
                          toedit.label == '' || toedit.label == prop.node.label
                        "
                        @click="edithandler()"
                        v-close-popup
                      ></q-btn>
                    </q-card-actions>
                  </q-card>
                </q-popup-proxy>
              </q-btn>
              <q-btn
                v-can="['KEPSEK', 'MAPEL', 'KURIKULUM']"
                flat
                size="sm"
                dense
                icon="delete_forever"
                color="negative"
                @click.stop="
                  todel = prop.node;
                  deleteGuard = true;
                "
              />
            </q-btn-group>
          </template>
        </q-tree>
      </q-card-section>
    </q-card>
    <q-dialog persistent v-model="deleteGuard">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="report_prblem" color="negative" text-color="white" />
          <span class="q-ml-sm">
            <strong class="q-ml-sm">
              Apa anda yakin ingin menghapus kompetensi dasar "{{
                todel.label
              }}"?
            </strong>
          </span>
        </q-card-section>
        <q-card-section>
          <p>
            silahkan tulis "{{ todel.label }}" tanpa tanda kutip untuk
            melanjutkan
          </p>
          <q-input outlined v-model="pass"></q-input>
        </q-card-section>

        <q-card-actions align="right" v-if="todel.label">
          <q-btn flat label="Tidak" @click="pass = ''" v-close-popup />
          <q-btn
            flat
            :disable="!(pass.toLowerCase() == todel.label.toLowerCase())"
            label="Ya"
            @click="delhandler(), (pass = '')"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: [],
      deleteGuard: false,
      pass: "",

      kd_baru: "",
      toedit: {},
      todel: {},
      max_uid: 0,

      jenjang: localStorage.getItem("jenjang"),
      customize: [],
    };
  },
  async mounted() {
    await this.getTreeData();
  },
  methods: {
    async addhandler(node) {
      let data = {
        id_mapel: node.id_mapel,
        tipe: node.label,
        nomor: node.children.length + 1,
        nama: this.kd_baru,
      };
      await this.$http.post(`/pengaturan/kompetensi/addkd`, data);
      let expanded = this.$refs.kdtree.getExpandedNodes();
      let temp = [];
      for (let item of expanded) {
        temp.push(item.uid);
      }
      this.kd_baru = "";
      await this.getTreeData();
    },
    async edithandler() {
      await this.$http.put(`/pengaturan/kompetensi/editkd`, this.toedit);
      await this.getTreeData();
    },
    async delhandler() {
      await this.$http.delete(`/pengaturan/kompetensi/delkd/${this.todel.id}`);
      this.todel = {};
      await this.getTreeData();
    },
    async getTreeData() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let temp = JSON.parse(JSON.stringify(this.customize));
      let resp = await this.$http.get(
        `/pengaturan/kompetensi/getdata/${localStorage.getItem("jenjang")}`
      );
      temp = resp.data;
      this.customize = temp;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
